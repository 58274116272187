import * as React from "react";
import styled from "styled-components";

import { moveGradient } from "../helpers/keyframes";

const Header = styled.div`
  background-color: #00566d;
  width: 100%;
  height: 100vh;
  min-height: 480px;
  max-height: 700px;
  position: relative;
  color: #fff;
`;

const Text = styled.div`
  background: linear-gradient(
    45deg,
    rgba(28, 142, 206, 0.8),
    rgba(195, 210, 0, 0.8)
  );
  background-size: 130% 130%;
  background-position: center;
  width: 66.666666%;
  padding-top: 40px;
  padding-bottom: 40px;
  position: absolute;
  bottom: 20vh;
  left: 0;
  animation: ${moveGradient} 12s ease infinite;

  @media (max-width: 767px) {
    width: 90%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 5%;
  }
`;

const Title = styled.h1`
  width: 90%;
  margin-bottom: 0.25em;
  font-size: 5rem;
  line-height: 1;

  @media (max-width: 767px) {
    font-size: 3.75rem;
  }
`;

const Content = styled.div`
  display: flex;

  @media (max-width: 767px) {
    display: block;
  }
`;

const ContentInner = styled.div`
  width: 66.666666%;
  padding-top: 7.2916%;
  padding-bottom: 7.2916%;
  position: relative;

  ::before {
    content: "";
    background-color: #fff;
    width: 100%;
    height: 70px;
    position: absolute;
    top: -70px;
    right: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-top: 70px;
    padding-left: 5%;

    ::before {
      display: none;
    }
  }
`;
const IntroText = styled.div`
  max-width: 760px;
  color: #00566d;
`;

const Logo = styled.div`
  background-color: #e8f4fa;
  width: 33.333333%;
  padding-top: 14.0625%;
  padding-bottom: 70px;

  svg {
    width: 112px;
    height: 112px;
  }

  @media (max-width: 1024px) {
    svg {
      width: 90px;
      height: 90px;
    }
  }

  @media (max-width: 767px) {
    background-color: transparent;
    width: 100%;
    padding-top: 0;

    svg {
      width: 70px;
      height: 70px;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <Header>
        <Text className="container-left">
          <Title>404 - Page Not Found</Title>
        </Text>
      </Header>

      <Content>
        <ContentInner className="container">
          <p>Sorry, we can&apos;t find the page you are looking for.</p>
        </ContentInner>
      </Content>
    </>
  );
};

export default NotFoundPage;
